.root {
    text-align: left;
    font-size: 15px;
}
.label {
    position: relative;
    font-size: 24px;
}
.altText {
    position: absolute;
    font-size: 10px;
    font-weight: normal;
    bottom: 0;
    right: 0;
}

.optionContainer {
    display: flex;
    align-items: center;
}
.teamsContainer {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.logoIcon {
    height: 1.5em;
    width: 1.5em;
}
.atSymbol {
    font-weight: bold;
    margin: 0 10px 0 10px;
}
.gameDate {
    font-size: 14px;
    font-style: italic;
}
