.statusFilterContainer {
    width: 100%;
    max-width: 130px;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.statusFilterContainer:hover {
    background-color: #505050;
}

.statusFilterLabel {
    text-align: left;
    font-size: 14px;
    color: white;
}

.statusFilterMenu {
    width: auto !important;
}

.gameContainer {
    padding: 0px 0px 0px 50px;
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    font-size: 16pt;
    flex-wrap: wrap;
}

.gameContainer > img {
    width: 18%;
}

.gameContainer > span {
    width: 30%
}

.teamThumbnail {
    border-radius: 5px 0px 0px 5px;
    flex: 1;
    max-height: 60px;
}

.statusFilter :global(.Dropdown-control) {
    border: none;
    color: white;
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    box-sizing: border-box;
    padding: 0px 15px 0px 0px;
    background-color: transparent;
    background-image: url("../assets/chevron-arrow-down.png");
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 10px;
    cursor: pointer;
}

.statusFilter :global(.Dropdown-menu) {
    width: 100%;
    max-height: 250px;
}

.statusFilter :global(.Dropdown-arrow) {
    border: none;
}

.cardContainer {
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.card {
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 1500px;
    margin: 0px auto 15px auto;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, .25);
    cursor: pointer;
    transition: 0.4s;
}

.card:hover {
    width: 82%;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, .25);
}

.expandable {
    cursor: default;
}

.cardContent {
    display: flex;
    text-decoration: none;
}

.cardContent:hover {
    cursor: pointer;
}

.selectable {
    margin: 0 0 1rem;
    padding: 0.5rem;
}

.selectable:hover {
    background-color: rgba(25, 118, 210, 0.08);
    cursor: pointer;
}

.cardItemContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 0px 5px 0px 5px;
}

.cardItem {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: center;
    text-align: left;
    color: #3F3F3F;
}

.cardItem > h1 {
    font-size: 14px;
    font-weight: normal;
    margin: 0px 0px 5px 0px;
    flex: 0 1 auto;
}

.cardItem > p {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    flex: 0 1 auto;
}

.thumbnail {
    height: 100%;
    border-radius: 5px 0px 0px 5px;
    flex: 1;
    max-height: 125px;
}

.thumbnailContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eventTimeContainer {
    display: flex;
    flex-direction: column;
}

.startTime {
    composes: cardItem;
}

.pgmStartTime {
    composes: cardItem;
    margin-top: 5px;
}

.startTime > h1, .pgmStartTime > h1 {
    margin: 0;
}

.startTime > p, .pgmStartTime > p {
    font-size: 14px;
}

.statusText {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.statusIcon {
    margin-left: 10px;
}

.cardItemSubscript {
    font-size: 12px;
    font-style: italic;
    color: #3F3F3F;
}

.actionContainer {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-end;
    composes: cardItem;
    border-radius: 5px;
    flex: .5;
}

.action {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 5px 5px 0px;
    border: none;
    flex: 0;
    transition: 0.4s;
    cursor: pointer;
}

.action:hover {
    flex: 1;
}

.action:hover, .action:focus {
    outline: none;
}

.actionArchive {
    composes: action;
    background-color: #2E7D32;
}

.actionDelete {
    composes: action;
    background-color: #C62828;
}

.actionIcon {
    margin: 0px 10px 0px 10px;
}

.popupContinueArchive {
    composes: popupContinueBtn from './CerebroPopup.module.css';
    background-color: #2E7D32;
}

.popupContinueDelete {
    composes: popupContinueBtn from './CerebroPopup.module.css';
    background-color: #C62828;
}
