.playerContainer {
    font-family: 'Avenir Next';
    color: #FFFFFF;
    background: #111111;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 480px;
}

.form {
    text-align: left;
    white-space: nowrap;
}

.inputGroup {
    margin-bottom: 1em;
}

.inputGroup.noWrap {
    flex-wrap: nowrap;
}

.contentContainer {
    font-family: 'Avenir Next';
    color: #3F3F3F;
    display: flex;
    flex-direction: row;
    padding-top: 10px;
}

.contentColumn {
    flex: 1;
    padding-top: 1%;
    min-width: 0;
}

.contentColumnLeft {
    composes: contentColumn;
    padding-left: 5%;
    padding-right: 1%;
}

.contentColumnRight {
    composes: contentColumn;
    padding-left: 1%;
    padding-right: 3%;
}

.contentColumnRightItem {
    composes: contentColumn;
    padding-bottom: 5%;
}

@media screen and (max-width: 575px) {
    .contentContainer {
        flex-direction: column-reverse;
    }

    .contentColumnLeft {
        padding-right: 5%;
    }

    .contentColumnRight {
        padding-left: 5%;
    }
}

.feedLabel {
    position: relative;
}

.labelContainer {
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 15px;
}

.inputGroupCheckbox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 15px;
}

.inputGroupCheckbox > input {
    margin-right: 5px;
}

:global(.react-datetime-picker--disabled) {
    background: none;
    color: #3F3F3F;
}

:global(.react-datetime-picker__inputGroup__input):disabled {
    color: #3F3F3F;
}

.cloneEventButton {
    composes: cerebro-button-submit from './Common.css';
    min-width: 150px;
    max-width: 200px;
    height: 40px;
    margin-bottom: 0;
    margin-right: 3%;
    margin-top: 3%;
    background-color: #B10DC9;
    vertical-align: middle;
}

.cancelEventButton {
    composes: cerebro-button-submit from './Common.css';
    min-width: 150px;
    max-width: 200px;
    height: 40px;
    margin-bottom: 0;
    margin-right: 3%;
    margin-top: 3%;
    background-color: #000000;
    vertical-align: middle;
}

.adButton {
    composes: cerebro-button-submit from './Common.css';
    min-width: 33.33%;
    max-width: 66.66%;
    height: 40px;
    margin: 5px 5px 5px 5px;
    background-color: navy;
}

.adBreakSection {
    text-align: center;
    padding: 20px;
    min-height: 20%;
    box-sizing: content-box;
    border-style: solid;
    display: block;
}

.adBreakButtons {
    display: grid;
    justify-items: center;
}

.adMessage {
    min-width: 60%;
    max-width: 60%;
    height: 40px;
    margin: inherit;
    color: red;
    font-weight: bold;
    display: table;
    white-space: normal;
}


.syncFeedsButton {
    composes: cerebro-button-submit from './Common.css';
    min-width: 150px;
    max-width: 200px;
    height: 40px;
    margin: auto;
    background-color: #808080;
}

@media screen and (max-width: 575px) {
    .cloneEventButton {
        margin-bottom: 3%;
        margin-right: 0;
    }
}

.liftSlateButton {
    composes: cerebro-button-submit from './Common.css';
    width: 70%;
    max-width: 160px;
    height: 40px;
    box-shadow: 0px 1px 2px rgba(40, 44, 52, .70);
    margin: auto;
    background-color: #CF142B;
}

.streamingButton {
    composes: cerebro-button-submit from './Common.css';
    width: 70%;
    max-width: 170px;
    height: 40px;
    box-shadow: 0px 1px 2px rgba(40, 44, 52, .70);
    margin: 0px;
    padding: 0px 5px 0px 5px;
}

.startStreamButton {
    composes: streamingButton;
    background-color: #33A532;
}

.stopStreamButton {
    composes: streamingButton;
    background-color: #CF142B;
}

.archiveButton {
    composes: streamingButton;
    background-color: #2E7D32;
}

.statusContainer {
    display: flex;
    flex-direction: column;
}

.status {
    display: inline;
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 5px;
}

.statusText {
    margin: 0px;
    font-weight: bold;
}

.statusActionsContainer {
    display: flex;
}

.statusActionsContainer > button {
    margin-right: 10px;
}

.slateContainer {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin-top: 15px;
}

