.cerebro-button-submit {
    font-family: 'Avenir Next';
    font-weight: bold;
    font-size: 14px;
    color: white;
    margin-bottom: 15px;
    background-color: #D94255;
    border: none;
    border-radius: 2px;
    box-shadow: 0px 1px 2px rgba(34,41,52,.70);
    cursor: pointer;
    padding: 0;
}
.cerebro-button-submit:active {
    outline: none;
    box-shadow: 0px 0px 5px rgba(34,41,52,.70) inset !important;
}
.cerebro-button-submit:focus{
    outline: none;
}
.cerebro-button-cancel {
    font-family: 'Avenir Next';
    font-size: 14px;
    background-color: Transparent;
    border: none;
    cursor: pointer;
    outline: none;
}
