html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

.App {
    text-align: center;
    max-width: 100%;
    margin: 0 auto;
    font-family: "Avenir Next";
}
