.alert {
    color: white;
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px 0px 15px;
}
.error {
    composes: alert;
    background-color: #FF4136;
}
.info {
    composes: alert;
    background-color: #2ECC40;
}
.warn {
    composes: alert;
    background-color: #FF851B;
}

.empty {
    flex: 1;
}
.message {
    flex: 90%;
}
.closeContainer {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.close {
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s;
}

:global(.cerebro-alert-enter) {
    height: 0px;
    font-size: 0px;
}

:global(.cerebro-alert-enter.cerebro-alert-enter-active) {
    height: 50px;
    transition: height 400ms ease-in-out;
}

:global(.cerebro-alert-leave) {
    height: 50px;
    font-size: 0px;
}

:global(.cerebro-alert-leave.cerebro-alert-leave-active) {
    height: 0px;
    transition: height 300ms ease-out;
}
