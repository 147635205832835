.saveButton {
    composes: cerebro-button-submit from './Common.css';
    min-width: 100px;
    max-width: 150px;
    height: 40px;
    margin-right: 25%;
    background-color: #2ECC40;
}
.cancelButton {
    composes: cerebro-button-submit from './Common.css';
    min-width: 100px;
    max-width: 150px;
    height: 40px;
    position: center;
    background-color: #FF0000;
}
.label {
    position: relative;
    font-size: 20px;
}

.row {
    display: flex;
    justify-content: center;
}

.inputGroupGame {
    margin-bottom: 1em;
}

.inputGroupGame.noWrap {
    flex-wrap: nowrap;
}

.inputGroup {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 15px;
}
.inputGroup input[type=text] {
    font-family: 'Avenir Next';
    font-size: 15px;
    width: 100%;
    resize: none;
    box-sizing: border-box;
    border: none;
    border-radius: 2px;
    background-color: #F8F8F8;
    color: #3F3F3F;
    box-shadow: 0px 1px 2px rgba(34,41,52,.70) inset;
    padding: 10px 5px 10px 5px;
}
.inputGroup input[type=text]:focus {
    outline: none;
}
.inputGroup textarea {
    font-family: 'Avenir Next';
    font-size: 15px;
    width: 100%;
    resize: none;
    box-sizing: border-box;
    border: none;
    border-radius: 2px;
    background-color: #F8F8F8;
    color: #3F3F3F;
    box-shadow: 0px 1px 2px rgba(34,41,52,.70) inset;
    padding: 10px 5px 10px 5px;
}
.inputGroup textarea:focus {
    outline: none;
}
.inputGroup > label {
    position: relative;
    font-size: 24px;
}
.inputGroupAltText {
    position: absolute;
    font-size: 10px;
    bottom: 0;
    right: 0;
}
.inputGroupCheckbox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;
}
.inputGroupCheckbox > input {
    margin-right: 5px;
}


