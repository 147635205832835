.slateContainer {
    flex: 0 0 auto;
    width: 256px;
    height: 164px;
    margin-right: 10px;
    cursor: pointer;
}
.slateImg {
    width: 256px;
    height: 144px;
    box-shadow: 0px 0px 3px rgba(34,41,52,.70);
}
.slateCaption {
    font-size: 12px;
    font-weight: normal;
    overflow: hidden;
}
.activeSlate {
    border: 5px solid red;
}

.disabledContainer {
    position: relative;
}
.overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom:0;
    background: rgba(255, 65, 54, .25);
}
