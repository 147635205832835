.popupContainer {
    display: flex;
    flex-direction: column;
}

.popupTitle {
    font-size: 32px;
    margin: 10px 30px 5px 30px;
}
.popupBody {
    font-size: 18px;
    font-weight: normal;
    margin: 0px 30px 0px 30px;
}
.popupImg {
    margin-top: 18px;
    width: 426px;
    height: 240px;
    align-self: center;
}
.popupActions {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 18px;
    margin-bottom: 10px;
    align-items: center;
    text-align: center;
}
.popupBtnContainer {
    flex: 1;
}
.popupCancelBtn, .popupContinueBtn {
    width: 135px;
    height: 40px;
    font-size: 16px;
    font-weight: normal;
    margin: 0;
}
.popupCancelBtn {
    composes: cerebro-button-cancel from './Common.css';
}
.popupContinueBtn {
    composes: cerebro-button-submit from './Common.css';
    background-color: #4AA6E2;
}
