.navContainer {
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
    background-color: #282C34;
    overflow-y: visible;
}
.navContent {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
}

.createEventContainer {
    flex: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    width: 100%;
}
.createEventLink {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    color: #f2f2f2;
    text-decoration: none;
    max-width: 180px;
    padding: 0px 15px 0px 15px;
}
.createEventLink:hover {
    background-color: #505050;
}
.createEventLinkSelected {
    background-color: #505050;
    pointer-events: none;
}
.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    padding: 0.5rem 1rem;
}

.logo:hover {
    background-color: #505050;
}

.logo img {
    height: 6vmin;
    cursor: pointer;
}

.elemContainer {
    flex: 60%;
    display: flex;
    flex-flow: row nowrap;
}
.left, .center, .right {
    display: flex;
    flex: 1;
    justify-content: stretch;
}
.center {
    align-items: stretch;
    justify-content: center;
}

.accountContainer {
    color: white;
    flex: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    padding: 0;
    cursor: pointer;
}

.account {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    padding: 0 1rem;
}

.account:hover {
    background-color: #505050;
}

.account img {
    height: 25px;
    margin-right: 5px;
}
