.ReactTags__tags {
    position: relative;
}

/* Styles for the input */
.ReactTags__tagInput {
    width: 200px;
    border-radius: 2px;
    display: inline-block;
}
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-family: "Avenir Next";
    font-size: 16px;
    width: 100%;
    border: 1px solid #EBEBEB;
    background-color: #FCFCFC;
    padding: 0 4px;
    outline: none;
    border-radius: 2px;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #F1F1F1;
    box-shadow: 0px 1px 2px rgba(0,0,0,0.5);
    background: rgba(241,241,241,.85);
    font-size: 16px;
    font-weight: normal;
    display: inline-block;
    padding: 5px;
    margin: 0 5px;
    cursor: move;
    border-radius: 2px;
}
.ReactTags__selected a.ReactTags__remove {
    color: #3F3F3F;
    margin-left: 5px;
    cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
    position: absolute;
}
.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: .05em .01em .5em rgba(0,0,0,.2);
    background: white;
    width: 200px;
}
.ReactTags__suggestions li {
    font-size: 15px;
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}
.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}
.ReactTags__tag {
    font-size: 16px;

}
