.label {
    display: flex;
    flex-direction: row;
    text-align: left;
    font-size: 24px;
    font-weight: normal;
    padding-bottom: 15px;
    justify-content: space-between;
    cursor: pointer;
}
.label span {
    font-weight: bold;
}

