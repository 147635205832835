.DateRangePicker {
    display: flex;
    align-items: center;
}

.DateRangePicker:hover {
    background-color: #505050;
}

.DateRangePicker_picker {
    margin-top: 1.0rem;
}

.DateRangePickerInput, .DateInput, .DateInput_input {
    background-color: transparent;
}

.DateInput_input, .DateInput_input::placeholder {
    color: white;
}
.DateInput_input {
    cursor: pointer;
    font-family: 'Avenir Next';
    font-weight: normal;
    font-size: 18px;
    text-align: center;
}
.DateInput_input__focused {
    border-bottom: 2px solid #0074D9;
}

.DateRangePickerInput_arrow {
    color: white;
    font-weight: bold;
}
.DateRangePickerInput_arrow_svg {
    fill: white;
    height: 18px;
    width: 18px;
}
.CalendarDay {
    outline: none;
}
.CalendarDay__selected_span {
    background: #66ABE8;
    color: white;
    border: 1px solid #99C7EF;
}
.CalendarDay__selected_span:hover {
    background: #0074D9;
    border: 1px double #4C9DE4;
}
.CalendarDay__selected_span:focus {
    outline: none;
}
.CalendarDay__selected {
    background: #0074D9;
    color: white;
    border: 1px solid #99C7EF;
}
.CalendarDay__selected:hover {
    background: #0074D9;
    color: white;
    border: 1px double #4C9DE4;
}
.CalendarDay__selected:focus {
    outline: none;
}
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: #66ABE8;
    border: 1px solid #99C7EF;
    color: white;
}
.DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid #0074D9;
}
.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
    border-right: 33px solid #66ABE8;
}

.DayPickerKeyboardShortcuts_show {
    outline: none;
}
