.submitButton {
    composes: cerebro-button-submit from './Common.css';
    width: 10%;
    height: 40px;
    background-color: #2ECC40;
}
.saveForLaterButton {
    composes: cerebro-button-submit from './Common.css';
    width: 10%;
    height: 40px;
    margin-right: 3%;
    background-color: #4AA6E2;
}
.createEvent {
    display: flex;
    flex-direction: column;
}

.column {
    display: flex;
    flex-direction: column;
    flex: 1;
    color: #3F3F3F;
    max-width: 475px;
}

.metaColumn {
    composes: column;
    margin: 2% 2% 1% 10%;
}

.elementalColumn {
    composes: column;
    margin: 2% 10% 1% 2%;
}

.row {
    display: flex;
    justify-content: center;
}


.inputGroupGame {
    margin-bottom: 1em;
}

.inputGroupGame.noWrap {
    flex-wrap: nowrap;
}

.inputGroup {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 15px;
}
.inputGroup input[type=text] {
    font-family: 'Avenir Next';
    font-size: 15px;
    width: 100%;
    resize: none;
    box-sizing: border-box;
    border: none;
    border-radius: 2px;
    background-color: #F8F8F8;
    color: #3F3F3F;
    box-shadow: 0px 1px 2px rgba(34,41,52,.70) inset;
    padding: 10px 5px 10px 5px;
}
.inputGroup input[type=text]:focus {
    outline: none;
}
.inputGroup textarea {
    font-family: 'Avenir Next';
    font-size: 15px;
    width: 100%;
    resize: none;
    box-sizing: border-box;
    border: none;
    border-radius: 2px;
    background-color: #F8F8F8;
    color: #3F3F3F;
    box-shadow: 0px 1px 2px rgba(34,41,52,.70) inset;
    padding: 10px 5px 10px 5px;
}
.inputGroup textarea:focus {
    outline: none;
}
.inputGroup > label {
    position: relative;
    font-size: 24px;
}
.inputGroupAltText {
    position: absolute;
    font-size: 10px;
    bottom: 0;
    right: 0;
}
.inputGroupCheckbox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;
}
.inputGroupCheckbox > input {
    margin-right: 5px;
}

.scheduleContainer {
    border: none;
    outline: none;
}
.datetimeGroup {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    column-gap: 10px;
    row-gap: 10px;
}
.programTimeContainer {
    display: flex;
}
.datetimeSelector {
    width: 100%;
    white-space: nowrap;
}
.datetimeSelector > div {
    font-family: 'Avenir Next';
    font-size: 13px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-radius: 2px;
    background-color: #F8F8F8;
    color: #3F3F3F;
    box-shadow: 0px 1px 2px rgba(34,41,52,.70) inset;
    padding: 10px 5px 10px 5px;
}
.datetimeSelector button, .datetimeSelector input,
.datetimeSelector select {
    outline: none;
}

.conductorField {
    text-align: left;
    font-size: 15px;
    margin-bottom: 15px;
}
.conductorLabel {
    font-size: 24px;
}

.dropdown > div, .dropdown > div:hover {
    font-family: 'Avenir Next';
    font-size: 15px;
    width: 100%;
    resize: none;
    box-sizing: border-box;
    border: none;
    border-radius: 2px;
    background-color: #F8F8F8;
    color: #3F3F3F;
    box-shadow: 0px 1px 2px rgba(34,41,52,.70) inset;
    padding: 10px 5px 10px 5px;
}
.dropdown > div:hover {
    cursor: pointer;
}
.invalidDropdown :global(.Dropdown-control), .invalidDropdown :global(.Dropdown-control):hover,
.invalidInput, .invalidDatetime :global(.react-datetime-picker__wrapper) {
    box-shadow: 0px 0px 5px rgba(255,65,54,.80) inset;
}
.invalidInputAlt {
    color: #FF4136;
    font-weight: bold;
}

.requiredField {
    color: #FF4136;
    display: inline;
}

.slateContainer {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
}

.thumbnailContainer {
    composes: thumbnailContainer from './EventDetail.module.css';
}
.thumbnail {
    composes: thumbnail from './EventDetail.module.css';
    max-width: 93%;
}
.thumbnailRemoveIcon {
    composes: thumbnailRemoveIcon from './EventDetail.module.css';
}
.labelContainer {
    composes: labelContainer from './EventDetail.module.css';
}
