.label {
    position: relative;
    font-size: 24px;
}
.description {
    align-self: flex-end;
    font-size: 14px;
    color: #FF4136;
    margin: 0;
}
.altText {
    position: absolute;
    font-size: 10px;
    bottom: 0;
    right: 0;
}
.input {
    font-family: 'Avenir Next';
    font-size: 15px;
    width: 100%;
    resize: none;
    box-sizing: border-box;
    border: 1px solid transparent;
    border-radius: 2px;
    background-color: #F8F8F8;
    color: #3F3F3F;
    box-shadow: 0px 1px 2px rgba(34,41,52,.70) inset;
    padding: 10px 5px 10px 5px;
}
.input:focus {
    outline: none;
}
.textarea {
    font-family: 'Avenir Next';
    font-size: 15px;
    width: 100%;
    resize: none;
    box-sizing: border-box;
    border: none;
    border-radius: 2px;
    background-color: #F8F8F8;
    color: #3F3F3F;
    box-shadow: 0px 1px 2px rgba(34,41,52,.70) inset;
    padding: 10px 5px 10px 5px;
}
.textarea:focus {
    outline: none;
}
.required {
    color: #FF4136;
    display: inline;
}
.invalid {
    box-shadow: 0px 0px 5px rgba(255,65,54,.80) inset;
}
