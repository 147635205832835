.labelContainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 15px;
}
.label {
    composes: label from './inputs/EditableInput.module.css';
    position: relative;
}
.altText {
    position: absolute;
    font-size: 10px;
    font-weight: normal;
    bottom: 0;
    right: 0;
}
