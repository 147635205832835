.playerContainer {
    font-family: 'Avenir Next';
    color: #FFFFFF;
    background: #111111;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 480px;
}


.inputGroup {
    margin-bottom: 1em;
}

.inputGroup.noWrap {
    flex-wrap: nowrap;
}


.autoScheduleBanner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    color: #fff;
    background: #2196f3;
}
.autoScheduleBannerIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
}

.contentContainer {
    font-family: 'Avenir Next';
    color: #3F3F3F;
    display: flex;
    flex-direction: row;
    padding-top: 10px;
}
.contentColumn {
    flex: 1;
    padding-top: 1%;
    min-width: 0;
}
.contentColumnLeft {
    composes: contentColumn;
    padding-left: 5%;
    padding-right: 1%;
}
.contentColumnRight {
    composes: contentColumn;
    padding-left: 1%;
    padding-right: 5%;
}

.contentColumnRightItem {
    composes: contentColumn;
    padding-bottom: 5%;
}
@media screen and (max-width: 575px) {
    .contentContainer {
        flex-direction: column-reverse;
    }
    .contentColumnLeft {
        padding-right: 5%;
    }
    .contentColumnRight {
        padding-left: 5%;
    }
}

.label {
    composes: label from './inputs/EditableInput.module.css';
    position: relative;
}
.labelContainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 15px;
}
.inputGroupCheckbox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;
}
.inputGroupCheckbox > input {
    margin-right: 5px;
}
.altText {
    position: absolute;
    font-size: 10px;
    font-weight: normal;
    bottom: 0;
    right: 0;
}

.scheduleContainer {
    border: none;
    outline: none;
}
.datetimeContainer {
    composes: container from './inputs/LabeledInput.module.css';
}
.datetimeGroup {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    column-gap: 10px;
    row-gap: 10px;
}
.programTimeContainer {
    display: flex;
}
.datetimeSelector {
    width: 100%;
    white-space: nowrap;
}
.datetimeSelector > div {
    font-family: 'Avenir Next';
    font-size: 18px;
    resize: none;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #EBEBEB;
    border-radius: 2px;
    color: #3F3F3F;
    padding: 5px 0px 5px 0px;
}
.datetimeSelector > div:hover {
    border: 1px solid #EBEBEB;
    border-radius: 2px;
    box-sizing: border-box;
    background: #FCFCFC;
}
.datetimeSelector > div:active, .datetimeSelector > div:focus,
.datetimeSelector > div:focus-within {
    border: 1px solid #EBEBEB;
    border-radius: 2px;
    background: #FCFCFC;
}
.datetimeSelector button, .datetimeSelector input,
.datetimeSelector select {
    outline: none;
}
.datetimeSelectorDisabled > div {
    font-family: 'Avenir Next';
    font-size: 18px;
    resize: none;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid transparent;
    color: #3F3F3F;
    padding: 5px 0px 5px 0px;
}
:global(.react-datetime-picker--disabled) {
    background: none;
    color: #3F3F3F;
}
:global(.react-datetime-picker__inputGroup__input):disabled {
    color: #3F3F3F;
}
.invalidDatetime :global(.react-datetime-picker__wrapper) {
    border: 1px solid transparent;
    background: #FCFCFC;
    box-shadow: 0px 0px 5px rgba(255,65,54,.80) inset;
}

.submitButton {
    composes: cerebro-button-submit from './Common.css';
    min-width: 150px;
    max-width: 200px;
    height: 40px;
    margin-bottom: 0;
    background-color: #4AA6E2;
}
.cloneEventButton {
    composes: cerebro-button-submit from './Common.css';
    min-width: 150px;
    max-width: 200px;
    height: 40px;
    margin-bottom: 0;
    margin-right: 3%;
    background-color: #B10DC9;
}
.bottomActionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px 0 25px 0;
}
@media screen and (max-width: 575px) {
    .bottomActionContainer {
        flex-direction: column;
    }
    .cloneEventButton {
        margin-bottom: 3%;
        margin-right: 0;
    }
}

.liftSlateButton {
    composes: cerebro-button-submit from './Common.css';
    width: 70%;
    max-width: 160px;
    height: 40px;
    box-shadow: 0px 1px 2px rgba(40,44,52,.70);
    margin: auto;
    background-color: #CF142B;
}
.streamingButton {
    composes: cerebro-button-submit from './Common.css';
    width: 70%;
    max-width: 170px;
    height: 40px;
    box-shadow: 0px 1px 2px rgba(40,44,52,.70);
    margin: 0px;
    padding: 0px 5px 0px 5px;
}
.startStreamButton {
    composes: streamingButton;
    background-color: #33A532;
}
.stopStreamButton {
    composes: streamingButton;
    background-color: #CF142B;
}
.updateStatusButton {
    composes: streamingButton;
    background-color: #002D72;
}
.archiveButton {
    composes: streamingButton;
    background-color: #2E7D32;
}
.deactivateScheduleButton {
    composes: streamingButton;
    background-color: #CF142B;
}

.gameDropdownLabel {
    font-weight: bold;
}

.url {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    align-self: flex-start;
}

.thumbnailContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    background: none;
    border: none;
}
.thumbnail {
    width: 100%;
    max-width: 426px;
    box-shadow: 0px 0px 3px rgba(34,41,52,.70);
    flex: 1;
}
.thumbnailRemoveIcon {
    height: 25px;
    width: 25px;
    margin-left: 2px;
    border-radius: 100%;
    fill: #FF4136;
    cursor: pointer;
}

.statusContainer {
    display: flex;
    flex-direction: column;
}
.status {
    display: inline;
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 5px;
}
.statusText {
    margin: 0px;
    font-weight: bold;
}
.autoScheduleNote {
    font-size: 18px;
    font-weight: normal;
    font-style: italic;
    margin-top: -5px;
    margin-bottom: 5px;
}
.statusActionsContainer {
    display: flex;
}
.statusActionsContainer > button {
    margin-right: 10px;
}

.slateContainer {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin-top: 15px;
}

.dropdown > div, .dropdown > div:hover {
    font-family: 'Avenir Next';
    font-size: 15px;
    font-weight: normal;
    width: 100%;
    resize: none;
    box-sizing: border-box;
    border: none;
    border-radius: 2px;
    background-color: #F8F8F8;
    color: #3F3F3F;
    box-shadow: 0px 1px 2px rgba(34,41,52,.70) inset;
    padding: 10px 5px 10px 5px;
}
.dropdown > div:hover {
    cursor: pointer;
}

.hidden {
    display: none;
}

.syncFeedsButton {
    composes: cerebro-button-submit from './Common.css';
    min-width: 150px;
    max-width: 200px;
    height: 40px;
    margin: 10px 10px 10px 10px;
    background-color: #808080;
}
