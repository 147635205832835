.root {
    text-align: left;
    font-size: 15px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.label {
    position: relative;
    font-size: 24px;
}

.altText {
    position: absolute;
    font-size: 10px;
    font-weight: normal;
    bottom: 0;
    right: 0;
}

.optionContainer {
    display: flex;
    align-items: center;
}

.teamsContainer {
    font-size: 12px;
    display: flex;
    width: 20%;
    text-align: right;
    margin-right: 0em;
    align-content: center;
    flex: 1;
}

.logoIcon {
    height: 1.5em;
    width: 1.5em;
}

.atSymbol {
    font-size: 10px;
    font-weight: bold;
    margin: auto 0.5em auto 0.5em;
}

.gameDate {
    width: 20%;
    font-size: 12px;
    font-style: italic;
    justify-content: flex-end;
}
