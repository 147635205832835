.label {
    position: relative;
    text-align: left;
    font-size: 24px;
    font-weight: bold;
    color: #3F3F3F;
}
.description {
    align-self: flex-end;
    font-size: 14px;
    color: #FF4136;
    margin: 0;
}
.altText {
    display: none;
}
.input {
    font-family: 'Avenir Next';
    font-size: 18px;
    resize: none;
    width: 100%;
    box-sizing: border-box;
    padding: 5px 20px 5px 0px;
    border: 1px solid transparent;
    background-image: url("../../assets/edit-field.png");
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 15px;
    background-color: transparent;
    color: #3F3F3F;
}
.input:hover {
    border: 1px solid #EBEBEB;
    border-radius: 2px;
    box-sizing: border-box;
    background: #FCFCFC;
    background-image: url("../../assets/edit-field.png");
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 15px;
}
.input:active, .input:focus {
    border: 1px solid #EBEBEB;
    border-radius: 2px;
    background: #FCFCFC;
    outline: none;
    padding: 5px 5px 5px 0px;
}
.inputDisabled {
    composes: input;
    background-image: none;
}
.inputDisabled:hover {
    border: 1px solid transparent;
    background: none;
}
.inputDisabled:active, .inputDisabled:focus {
    border: 1px solid transparent;
    background: none;
    outline: none;
}

.required {
    color: #FF4136;
    display: inline;
    font-weight: normal;
}
.invalid, .invalid:hover, .invalid:active, .invalid:focus {
    border: 1px solid transparent;
    background: #FCFCFC;
    background-image: none;
    box-shadow: 0px 0px 5px rgba(255,65,54,.80) inset;
    padding: 5px 20px 5px 5px;
}
