
.column {
    display: flex;
    flex-direction: column;
    flex: 1;
    color: #3F3F3F;
    max-width: 475px;
}

.meta {
    composes: column;
    margin: 2% 2% 2% 2%;
}

.row {
    display: flex;
    justify-content: center;
}

.halfWidth {
    width: 50%;
}

.input {
    margin-bottom: 1em;
}

.inputGroup2 > label {
    position: relative;
    font-size: 24px;
}

.inputGroupCheckbox {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.slateContainer {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    margin: auto;
}
